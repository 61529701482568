<template lang="pug">
span
  v-btn(
    color="primary",
    text,
    small,
    v-if="valid",
    @click="handleEdit",
    :loading="loading"
  ) Review
  ReviewForm(ref="reviewForm")
</template>

<script>
import ReviewForm from "/libs/components/Review/Form";

export default {
  components: { ReviewForm },
  props: ["order"],
  data() {
    return { loading: false };
  },
  computed: {
    valid() {
      return (
        this.order && this.order.status >= 0 && this.order.needed < Date.now()
      );
    },
  },
  methods: {
    async handleEdit() {
      if (!this.order) return;
      this.loading = true;
      const params = { criteria: { order: this.order._id } };
      try {
        const review = await this.$api.review.retrieve(params);
        if (review) this.$refs.reviewForm.open(review);
        else this.createReview();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    createReview() {
      if (!this.order) return;
      const user = this.$auth.user();
      if (!user) return;
      const review = {
        author: {
          id: user._id,
          name: user.name.preferred || user.name.first || "",
        },
        biz: this.order.seller.id,
        order: this.order._id,
        title: "",
        stars: 5,
        content: "",
      };
      this.$refs.reviewForm.open(review);
    },
  },
};
</script>