<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(
      color="secondary",
      v-if="visible",
      text,
      small,
      @click="load",
      v-on="on"
    ) Contact Seller
  v-card(flat)
    v-toolbar(flat, dense)
      .subtitle-2 Contact Seller
    v-card-text
      .caption Please understand that restaurant could be busy and not reply promptly. Please keep the conversation concise and to the point. That can help restaurant staff work it out faster. A phone call could be an alternative if the request is urgent.
      Messages.my-2(:conversation="conversation")
      v-textarea(
        clearable,
        no-resize,
        rows="2",
        outlined,
        hide-details,
        v-model="content",
        label="Message",
        :counter="300",
        :disabled="!postable"
      )
    v-card-actions
      v-spacer
      v-btn(
        color="primary",
        text,
        invalid="!postable",
        @click="post",
        :loading="loading"
      ) Send
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import Messages from "./Messages/index";

export default {
  components: { Messages },
  props: ["order"],
  data() {
    return {
      loading: false,
      dialog: false,
      content: "",
    };
  },
  computed: {
    ...mapGetters(["conversation"]),
    cutoff() {
      return moment().startOf("day").valueOf();
    },
    visible() {
      return this.order && this.order.needed >= this.cutoff;
    },
    name() {
      return this.order.customer && this.order.customer.name;
    },
    postable() {
      if (!this.conversation) return true;
      return this.conversation.status == "open";
    },
  },
  methods: {
    ...mapActions(["setConversation"]),
    async load() {
      const params = { order_id: this.order._id };
      try {
        const url = "/orders/conversation/retrieve";
        const res = await this.axios.post(url, params);
        this.setConversation(res.data);
      } catch (e) {
        console.log(e.response?.data || e.message);
      }
    },
    async post() {
      if (
        !this.content ||
        this.content.length < 2 ||
        this.content.length > 300
      ) {
        alert("Please enter a message between 2 and 300 characters.");
        return;
      }
      const params = {
        order_id: this.order._id,
        message: {
          name: this.name || "customer",
          content: this.content,
          channel: "customer",
        },
      };
      this.loading = true;
      try {
        const url = "/orders/conversation/post";
        const res = await this.axios.post(url, params);
        this.setConversation(res.data);
        this.content = "";
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>