<template lang="pug">
.biz-body
  div(v-if="!isCheckout")
    v-progress-linear.my-0(indeterminate, v-if="loading")
    Info(@showMenu="showMenu = true")
  div(v-if="biz")
    router-view#bizMainCon
    v-container(v-if="isMainPath")
      Reviews
      SuggestedBiz(v-if="isNormal")
  CartButton(v-if="!isCheckout")
  NotFound(v-if="notFound")
  ChainDialog
  v-bottom-sheet(v-model="sheet")
    v-sheet.text-center
      v-btn.my-3(@click="showCoupon()", color="secondary", rounded) Show Coupon
      .pa-3.subtitle-2 Don't forget to claim and apply coupon in checkout.
</template>

<script>
import Info from "./Info/index";
import CartCheckout from "./../Checkout/index";
import Reviews from "./Reviews";
import SuggestedBiz from "./SuggestedBiz";
import CartButton from "./CartButton";
import NotFound from "./NotFound";
import ChainDialog from "./ChainDialog";
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import { EventBus } from "../../../event-bus";

export default {
  components: {
    Info,
    CartCheckout,
    Reviews,
    SuggestedBiz,
    CartButton,
    NotFound,
    ChainDialog,
  },
  props: ["url"],
  metaInfo() {
    if (this.biz) {
      const title = this.title;
      const description = `Order food online from ${this.biz.name} in ${this.city} - menu, reviews, coupons and more.`;
      const content = this.biz?.description || description;
      return { title, meta: [{ name: "description", content }] };
    }
    return {};
  },
  data() {
    return {
      mode: process.env.VUE_APP_MODE,
      showMenu: true,
      loading: false,
      notFound: false,
      sheet: false,
    };
  },
  computed: {
    ...mapGetters([
      "biz",
      "chain",
      "cities",
      "promotions",
      "cartBiz",
      "confirmedBiz",
    ]),
    isNormal() {
      return this.mode == "normal";
    },
    city() {
      return this.biz?.address?.city || "";
    },
    title() {
      let items = [];
      if (this.biz?.name) items.push(this.biz.name);
      if (this.city) items.push(this.city);
      if (this.isNormal) items.push("Selflane");
      return items.join(" | ");
    },
    isMainPath() {
      const path = this.$route.path;
      const items = ["/reserve", "/checkout", "/member"];
      return !items.some((o) => path.includes(o));
    },
    isCheckout() {
      return this.$route.path?.includes("/checkout");
    },
  },
  watch: {
    url(val, oldVal) {
      if (val && val != oldVal) this.load();
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    ...mapActions([
      "setBiz",
      "setCartBiz",
      "setBizService",
      "setSelectedCity",
      "selectedCity",
      "setCartGeoService",
      "updateCartPayment",
      "setWeeklySpecials",
      "setReviews",
      "setChain",
      "setPromotions",
      "setClaims",
    ]),
    async load() {
      this.notFound = false;
      this.setBiz(null);
      this.loading = true;
      try {
        const params = { criteria: { url: this.url } };
        const { data } = await this.axios.post("/bizs/retrieve", params);
        this.setBiz(data);
        this.refreshCartBiz();
      } catch (e) {
        this.notFound = true;
      }
      this.loading = false;
      if (!this.biz) return;
      this.updateSelectedCity();
      this.loadBizService();
      this.loadGeoService();
      this.loadWeeklySpecials();
      this.loadReviews();
      this.loadChain();
    },
    async loadBizService() {
      const bizId = this.biz?._id;
      if (!bizId) {
        this.setBizService(null);
        return;
      }
      try {
        const params = { criteria: { biz: bizId }, select: "member pos" };
        const result = await this.$api.bizService.retrieve(params);
        this.setBizService(result);
      } catch (e) {
        this.setBizService(null);
      }
    },
    refreshCartBiz() {
      if (!this.biz) return;
      if (this.biz._id == this.cartBiz._id) this.setCartBiz(this.biz);
    },
    /**
     * If the user has never set up a default address, set the city of the loaded business as the default address
     */
    updateSelectedCity() {
      if (!this.biz) return;
      if (this.selectedCity) return;
      const address = this.biz.address;
      const found = this.cities.find((o) => {
        return (
          o.city.trim() == address.city.trim() &&
          o.state.trim() == address.state.trim()
        );
      });
      if (found) this.setSelectedCity(found);
    },
    async loadGeoService() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      try {
        const res = await this.axios.post("/bizs/getGeoService", params);
        this.setCartGeoService(res.data);
        this.updateCartPayment();
      } catch (e) {
        //
      }
    },
    async loadWeeklySpecials() {
      if (!this.biz) {
        this.setWeeklySpecials([]);
        return;
      }
      const params = {
        criteria: { biz: this.biz._id, end: { $gt: Date.now() } },
      };
      try {
        const url = "/discount/weeklySpecial/list";
        const { data } = await this.axios.post(url, params);
        this.setWeeklySpecials(data);
      } catch (e) {
        this.setWeeklySpecials([]);
      }
    },
    async loadReviews() {
      this.setReviews([]);
      if (!this.biz) return;
      const params = {
        criteria: { biz: this.biz._id, status: { $ne: -1 } },
        limit: 50,
      };
      const result = await this.$api.review.list(params);
      const sorted = _.chain(result)
        .sortBy((o) => -o.created)
        .sortBy((o) => -o.stars)
        .sortBy((o) => -o.liked.length)
        .value();
      this.setReviews(sorted);
    },
    async loadChain() {
      this.setChain(null);
      if (!this.biz) return;
      const params = { criteria: { bizs: this.biz._id } };
      const chain = await this.$api.chain.account.retrieve(params);
      this.setChain(chain);
      if (chain && !chain.bizs.includes(this.confirmedBiz)) {
        EventBus.$emit("show-chain-dialog", chain);
      }
      this.loadCoupons();
    },
    async loadCoupons() {
      this.setPromotions([]);
      if (!this.biz) return;
      let params;
      const expiry = { $gt: Date.now() };
      if (this.chain) {
        params = {
          criteria: {
            $or: [{ biz: this.biz._id }, { chain: this.chain._id }],
            expiry,
          },
        };
      } else {
        params = { criteria: { biz: this.biz._id, expiry } };
      }
      const result = await this.$api.promotion.list(params);
      this.setPromotions(result);
      if (this.promotions?.length && this.isMainPath) {
        this.sheet = true;
        this.loadClaims();
      }
    },
    async loadClaims() {
      if (!this.$auth.check()) return;
      const ids = this.promotions.map((o) => o._id);
      if (!ids?.length) return;
      const params = {
        criteria: { promotion: { $in: ids }, user: this.$auth.user()._id },
        select: "promotion applied",
      };
      const { data } = await this.axios.post("/promoclaim/list", params);
      this.setClaims(data);
    },
    showCoupon() {
      this.$vuetify.goTo("#coupon-table");
      this.sheet = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.biz-body
  background-color: #F0F2F5
</style>