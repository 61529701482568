import { render, staticRenderFns } from "./index.vue?vue&type=template&id=98a3f3ba&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=98a3f3ba&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.35_css-loader@6.11.0_webpack@5.93.0__lodash@4.17.21__yqsslhmmprckquc37dxatwx3py/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98a3f3ba",
  null
  
)

export default component.exports