import { render, staticRenderFns } from "./Tickets.vue?vue&type=template&id=7129166f&scoped=true&lang=pug"
import script from "./Tickets.vue?vue&type=script&lang=js"
export * from "./Tickets.vue?vue&type=script&lang=js"
import style0 from "./Tickets.vue?vue&type=style&index=0&id=7129166f&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.35_css-loader@6.11.0_webpack@5.93.0__lodash@4.17.21__yqsslhmmprckquc37dxatwx3py/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7129166f",
  null
  
)

export default component.exports