<template lang="pug">
IconBtn(@click="download()", title="PDF", icon="mdi-printer")
</template>

<script>
import jsPDF from "jspdf";
import _ from "underscore";

export default {
  props: ["order", "delivOrder"],
  computed: {
    serviceFee() {
      return this.order?.payment?.selflane?.items?.find(
        (o) => o.name == "service_fee"
      )?.value;
    },
    deliveryFee() {
      return (
        this.order?.payment?.selflane?.items?.find(
          (o) => o.name == "delivery_fee"
        )?.value || this.order?.payment?.fee
      );
    },
    distanceSurcharge() {
      return this.order?.payment?.selflane?.items?.find(
        (o) => o.name == "distance_surcharge"
      )?.value;
    },
    tip() {
      return (
        this.order?.payment?.selflane?.items?.find(
          (o) => o.name == "delivery_tip"
        )?.value || this.order?.payment?.tip
      );
    },
  },
  methods: {
    download() {
      if (!this.order) return;
      const currency = this.$options.filters.currency;
      const phone = this.$options.filters.phone;
      const address = this.$options.filters.address;
      const datetime = this.$options.filters.datetime;
      const time = this.$options.filters.time;
      const modifiers = this.$options.filters.modifiers;

      let doc = new jsPDF({ format: "letter" });
      let x = 19;
      let y = 25;
      let pageHeight = doc.internal.pageSize.height;

      addTitle(this.order);
      addLine();
      addCustomer(this.order);
      addLine();
      addItems(this.order);

      // add payments
      this.order.payment.service?.items?.forEach((o) => {
        addTableLine(o.name, currency(o.value));
      });
      if (this.order.payment.subtotal) {
        addTableLine("Subtotal", currency(this.order.payment.subtotal));
      } else {
        // fallback for old orders
        addTableLine("Subtotal", currency(this.order.payment.dish));
      }
      if (this.order.payment.tax_items) {
        this.order.payment.tax_items.forEach((o) => {
          if (o.type === 1) return;
          addTableLine(o.name + " " + o.percentage + "%", currency(o.value));
        });
      } else {
        addTableLine("Tax", currency(this.order.payment.tax));
      }
      if (this.order.payment.gift) {
        addTableLine("Gift", currency(this.order.payment.gift));
      }
      if (this.serviceFee) {
        addTableLine("Service Fee", currency(this.serviceFee));
      }
      if (this.deliveryFee) {
        addTableLine("Delivery Fee", currency(this.deliveryFee));
      }
      if (this.distanceSurcharge) {
        addTableLine("Distance Surcharge", currency(this.distanceSurcharge));
      }
      if (this.tip) {
        addTableLine("Tip", currency(this.tip));
      }
      this.order.payment.deduction?.items?.forEach((o) => {
        addTableLine(o.name, currency(-o.value));
      });
      addTableLine("Total", currency(this.order.payment.total));
      nextLine(4);
      addLine();
      // add charge
      addTableLine("Payments", "");
      _.each(this.order.splitChecks, (check) => {
        if (check.payment.charge?.amount) {
          addTableLine(
            "Online" + " ..." + check.payment.card.last4,
            currency(check.payment.charge.amount)
          );
        }
        _.each(check.payment.charges, (o) => addCharge(o));
      });
      if (this.order.payment.charge?.amount) {
        addTableLine(
          "Online" + " ..." + this.order.payment.card.last4,
          currency(this.order.payment.charge.amount)
        );
      }
      this.order.payment.charges?.forEach((o) => addCharge(o));
      addLine();
      if (
        this.order.status >= 0 &&
        this.delivOrder &&
        this.delivOrder.driver &&
        this.delivOrder.pickupTime &&
        this.delivOrder.deliverTime
      ) {
        addTableLine("Driver", this.delivOrder.driver.name);
        addTableLine("Pick-up time", time(this.delivOrder.pickupTime));
        addTableLine("Deliver time", time(this.delivOrder.deliverTime));
        addLine();
      }
      function addCharge(charge) {
        if (charge?.amount) {
          let title = charge.method;
          if (charge.last4) {
            title += " ..." + charge.last4;
          }
          addTableLine(title, currency(charge.amount));
        }
      }
      addTableLine("Order was created at " + datetime(this.order.created), "");

      doc.save("order_receipt.pdf");
      this.$toast.success("Receipt downloaded.");

      function addTitle(order) {
        doc.setFontSize(12);
        if (order.status < 0) {
          doc.text("This order is canceled.", x, y);
          nextLine(4);
          addLine();
        }
        doc.text(order.seller.name, x, y);
        doc.setFontSize(10);
        nextLine();
        doc.text(phone(order.seller.phone), x, y);
        nextLine();
        doc.text(address(order.seller.address), x, y);
        nextLine(4);
      }

      function addCustomer(order) {
        doc.text("#" + order.orderNumber, x, y);
        nextLine();
        doc.text(datetime(order.needed), x, y);
        let name = order.customer?.name || "";
        let phone = order.customer?.phone || "";
        let line = (name + " " + phone).trim();
        if (line) {
          nextLine();
          doc.text(line, x, y);
        }
        if (order.type == "delivery" && order.customer.address?.line1) {
          nextLine();
          doc.text(address(order.customer.address), x, y);
          if (order.customer.address?.note) {
            nextLine();
            doc.text(order.customer.address.note, x, y);
          }
        }
        nextLine(4);
      }

      function addItems(order) {
        order.dishes.forEach((dish) => {
          addTableLine(dish.name, currency(dish.price) + "X" + dish.quantity);
          const mods = modifiers(dish.modifiers);
          if (mods && mods.length) {
            if (Array.isArray(mods)) {
              _.each(mods, (o) => addTableLine(o, "", 8));
            } else {
              addTableLine(mods, "", 8);
            }
          }
        });
        order.gifts?.forEach((o) => {
          addTableLine("Gift Card", currency(o.amount));
        });
        order.vouchers?.forEach((o) => {
          addTableLine(o.name, currency(-o.amount));
        });
        nextLine(4);
      }

      function addTableLine(left, right, fontSize = 10) {
        doc.setFontSize(fontSize);
        doc.text(left, x, y);
        nextCell(150);
        doc.text(right, x, y);
        nextLine(6);
      }

      function addLine(height) {
        doc.line(x, y, x + 180, y);
        nextLine(height);
      }
      function nextLine(height) {
        x = 19;
        if (y + 25 > pageHeight) {
          doc.addPage();
          y = 25;
          return;
        }
        if (height) y += height;
        else y += 8;
        x = 19;
      }
      function nextCell(width) {
        if (width) x += width;
        else x += 40;
      }
    },
  },
};
</script>