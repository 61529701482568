<template lang='pug'>
.item(v-if="item.amount", @click="$emit('click')")
  .item-name
    div
      span.text-capitalize {{ item.method }}
      span.ml-1(v-if="item.brand") {{ item.brand }}
      span.ml-1(v-if="item.last4") ...{{ item.last4 }}
      span.ml-1(v-if="item.name") {{ item.name }}
    div(v-if="item.approve")
      span Auth: {{ item.approve }}
      span.ml-1(v-if="item.device") Terminal: {{ item.device.name }}
        span.ml-1 ID: {{ item.transId }}
        span.ml-1(v-if="isInternal") {{ item.device.loc }}
    div {{ item.time | time }}
  span {{ item.amount | currency }}
  v-btn(
    icon,
    x-small,
    :href="url",
    target="_blank",
    color="secondary",
    v-if="url"
  )
    v-icon mdi-link
</template>

<script>
export default {
  props: ["item", "isInternal"],
  computed: {
    url() {
      if (!this.isInternal) return "";
      if (this.item.transId && this.item.transId.length > 5) {
        return `https://dashboard.stripe.com/payments/${this.item.transId}`;
      }
      return "";
    },
  },
};
</script>

<style lang="sass" scoped>
.item
  display: flex
  align-items: top
  font-size: 13px
  gap: 5px
  cursor: pointer

  &-name
    flex-grow: 1
</style>