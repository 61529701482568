<template lang="pug">
v-btn(
  :small="small",
  :x-small="xSmall",
  :outlined="outlinedStyle",
  :text="text",
  :color="color",
  v-on="$listeners",
  :loading="loading",
  :icon="iconOnly"
)
  v-icon(small, :left="left") {{ icon }}
  span.text-notransform {{ title }}
</template>

<script>
export default {
  name: "IconBtn",
  props: {
    title: { type: String, default: "Edit" },
    icon: { type: String, default: "mdi-pencil" },
    color: { type: String, default: "secondary" },
    loading: Boolean,
    small: { type: Boolean, default: true },
    xSmall: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    text: { type: Boolean, default: false },
  },
  computed: {
    iconOnly() {
      return !this.title;
    },
    left() {
      if (this.iconOnly) return false;
      return true;
    },
    outlinedStyle() {
      if (this.iconOnly) return false;
      return this.outlined;
    },
  },
};
</script>